import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ProfilePortfolioCard } from '@entities/portfolio';
import { PortfolioEdit } from '@features/portfolio/portfolio-edit';
import { IsDefined } from '@shared/lib/js-ts';
const PortfolioView = ({ portfolio, portfolioIdToEdit = undefined, selected, onClick, onAction, onEditClose, }) => {
    const handleOnClick = () => {
        onClick(portfolio.id);
    };
    return (_jsxs(_Fragment, { children: [(portfolioIdToEdit !== portfolio.id) &&
                (_jsx(ProfilePortfolioCard, { portfolio: portfolio, viewType: 'card', selected: selected, onClick: handleOnClick, onAction: onAction })), IsDefined(portfolioIdToEdit) &&
                portfolioIdToEdit === portfolio.id && (_jsx(PortfolioEdit, { portfolio: portfolio, onEditClose: onEditClose }))] }));
};
export default PortfolioView;
