import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ProfilePortfolioCard } from '@entities/portfolio';
import PortfolioView from '../PortfolioView';
const ListContent = ({ portfolios, isLoading = false, portfolioToEdit = undefined, selectedPortfolioId = undefined, selectPortfolio, onPortfolioAction, onEditClose, }) => {
    if (isLoading) {
        return (_jsxs(_Fragment, { children: [_jsx(ProfilePortfolioCard, { viewType: 'card', isLoading: true }, 'card-skeleton-1'), _jsx(ProfilePortfolioCard, { viewType: 'card', isLoading: true }, 'card-skeleton-2'), _jsx(ProfilePortfolioCard, { viewType: 'card', isLoading: true }, 'card-skeleton-3'), _jsx(ProfilePortfolioCard, { viewType: 'card', isLoading: true }, 'card-skeleton-4')] }));
    }
    return portfolios.map((portfolio) => (_jsx(PortfolioView, { portfolio: portfolio, portfolioIdToEdit: portfolioToEdit === null || portfolioToEdit === void 0 ? void 0 : portfolioToEdit.id, selected: portfolio.id === selectedPortfolioId, onClick: selectPortfolio, onAction: onPortfolioAction, onEditClose: onEditClose }, portfolio.id)));
};
export default ListContent;
